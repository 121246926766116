<template>
  <fw-layout wide mobile-ready :notfound="notFound">
    <template #main-content>
      <fw-panel :title="$t('receipts')" featured>
        <fw-panel :title="$t('currentYear')" class="mt-5">
          <LoadingPlaceholder v-if="loadingCurrentYear" :list="false" />
          <div v-else-if="receipts && receipts.length" class="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-7 gap-4">
            <button
              v-for="(receipt, index) in receipts"
              :key="index"
              class="bg-white w-full h-full flex justify-center flex-col gap-1 p-3 hover:shadow-xl rounded-lg"
              :aria-label="$t('receipt')"
              :disabled="downloading"
              @click="openPreview(receipt)"
            >
              <div class="flex gap-3 justify-between w-full">
                <div class="flex-1">
                  <fw-icon-file-pdf class="h-5 w-5 text-gray-500"></fw-icon-file-pdf>
                </div>
                <div v-if="isNewReceipt(receipt)" class="relative flex gap-1">
                  <fw-tag type="primary" marginless>{{ $t('new') }}</fw-tag>
                </div>
              </div>
              <div class="flex-1 flex items-center flex-col self-center mb-2">
                <h2 class="font-semibold text-lg text-center px-2 mb-2">{{ $t(`months.${receipt.month}`) }}</h2>
                <div class="text-gray-400 text-sm font-bold opacity-75">{{ year }}</div>
              </div>
            </button>
          </div>
          <fw-panel-info v-else centered empty class="p-3">
            {{ $t('noReceipts') }}
          </fw-panel-info>
        </fw-panel>

        <div class="mt-5 mb-1">
          <fw-heading size="h4" muted>{{ $t('previousYears') }}</fw-heading>
        </div>

        <div v-if="!isObjectEmpty(receiptsCount)">
          <b-dropdown aria-role="list" position="is-bottom-right">
            <fw-button-dropdown
              slot="trigger"
              aria-role="listitem"
              type="transparent"
              size="xl"
              :label="`${selectedYear || ''}`"
            >
            </fw-button-dropdown>
            <b-dropdown-item
              v-for="(count, receiptYear) in receiptsCount"
              :key="receiptYear"
              :label="`${receiptYear}`"
              aria-role="listitem"
              :class="`font-medium ${receiptYear == selectedYear ? 'text-primary' : 'text-gray-500'}`"
              @click.native="getPaySlipsByYear(receiptYear)"
            >
              {{ receiptYear }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <fw-panel :counter="otherYearsReceipts ? otherYearsReceipts.length : 0" boxed="xs" class="mb-5 -mt-2">
          <LoadingPlaceholder v-if="loading" list />
          <RecycleScroller
            v-else-if="otherYearsReceipts && otherYearsReceipts.length > 0"
            v-slot="{ item }"
            key-field="key"
            :items="otherYearsReceipts"
            :item-size="44"
            :buffer="50"
          >
            <div class="p-0.5 border-b border-gray-100">
              <RecordReceipt :item="item" @download="downloadReceipt(item)" @preview="openPreview(item)" />
            </div>
          </RecycleScroller>

          <fw-panel-info v-else centered empty class="p-3">
            {{ $t('noReceipts') }}
          </fw-panel-info>
        </fw-panel>
      </fw-panel>

      <fw-panel-info debug label="Receipts (raw)">
        <json-viewer
          :value="{
            receipts,
            otherYearsReceipts,
            receiptsCount,
            selectedYear,
            previewOpen,
            selectedFile
          }"
        ></json-viewer>
      </fw-panel-info>
    </template>

    <template #modals>
      <fw-modal :active="previewOpen" size="full" @close="closePreview">
        <PanelPreviewMedia
          v-if="selectedFile"
          :file="selectedFile.file"
          :title="`RV ${$t(`months.${selectedFile.month}`)} ${selectedFile.year}`"
          :emit-download="true"
          @download="downloadReceipt(selectedFile)"
          @close="closePreview"
        />
        <template #footer>
          <fw-button type="black" expanded @click.native="closePreview">{{ $t('close') }}</fw-button>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import RecordReceipt from '@/components/records/RecordReceipt'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import { RecycleScroller } from 'vue-virtual-scroller'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PanelPreviewMedia from '@/fw-modules/fw-core-vue/ui/components/panels/PanelPreviewMedia'

// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    RecycleScroller,
    RecordReceipt,
    LoadingPlaceholder,
    PanelPreviewMedia
  },

  data() {
    return {
      loading: true,
      loadingCurrentYear: true,
      downloading: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      receipts: [],
      otherYearsReceipts: [],
      receiptsCount: {},
      selectedYear: null,
      previewOpen: false,
      selectedFile: null
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    betamode() {
      return Boolean(localStorage.getItem('beta'))
    },

    notFound() {
      return !this.betamode && !this.debugmode
    }
  },

  created() {
    ServiceSettings.checkAppVersion()
  },

  mounted() {
    this.getPaySlips()
    this.getPaySlipsByYear(this.year - 1)
  },

  methods: {
    openPreview(file) {
      this.selectedFile = file
      this.previewOpen = true
    },

    closePreview() {
      console.log('closePreview :>> ')
      this.previewOpen = false
      this.selectedFile = null
    },

    async getPaySlips() {
      this.loadingCurrentYear = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserReceipts({
          year: this.year,
          type: 'pay_slips'
        })
        console.log('getPaySlips :>> ', response)
        this.receipts = response.receipts?.pay_slips
        this.receiptsCount = response.years_count
      })

      this.loadingCurrentYear = false
    },

    async getPaySlipsByYear(year = null) {
      this.loading = true
      this.selectedYear = year

      if (!year) {
        year = Object.keys(this.receiptsCount)[0]
      }

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserReceipts({
          year: year,
          type: 'pay_slips'
        })
        console.log('getPaySlipsByYear :>> ', response)
        this.otherYearsReceipts = response.receipts?.pay_slips
        this.receiptsCount = response.years_count

        if (this.year in this.receiptsCount) delete this.receiptsCount[this.year]

        if (!(this.year - 1 in this.receiptsCount)) {
          this.receiptsCount[this.year - 1] = 0
        }
      })

      this.loading = false
    },

    downloadReceipt(file) {
      console.log('downloadReceipt :>> ', file)
      if (!file) return

      this.downloading = true

      this.api.setUserReceiptSeen(file.key, true).then(() => {
        file.seen = true
      })

      const url = ServiceStorage.getFileUrl(file.file, this.user.token)
      utils.downloadFile(url, file.file.filename)
      this.downloading = false
    },

    isNewReceipt(receipt) {
      return receipt.month == this.month && receipt.year == this.year && !receipt.seen
    },

    getFileMediaType(file) {
      if (file.filename) return '.' + file.filename.slice(file.filename.lastIndexOf('.') + 1)

      return file.type
    },

    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "currentYear": "Ano atual",
    "previousYears": "Anos anteriores",
    "receipts": "Recibos de vencimento",
    "noReceipts": "Sem recibos de vencimento",
    "receipt": "Recibo",
    "new": "Novo",
    "holiday": "Feriado",
    "P10": "Entrada",
    "P20": "Saída",
    "happyBirthday": "Parabéns",
    "dayIsYours": "Este dia é seu",
    "vacations": "Férias",
    "allDay": "Todo o dia",
    "halfDay": "Meio dia",
    "months": {
      "1": "Janeiro",
      "2": "Fevereiro",
      "3": "Março",
      "4": "Abril",
      "5": "Maio",
      "6": "Junho",
      "7": "Julho",
      "8": "Agosto",
      "9": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro"
    }
  },
  "en": {
    "close": "Close",
    "currentYear": "Current year",
    "previousYears": "Previous years",
    "receipt": "Pay slips",
    "receipts": "Pay slips",
    "noReceipts": "No pay slips",
    "new": "New",
    "holiday": "Holiday",
    "P10": "Enter",
    "P20": "Exit",
    "happyBirthday": "Happy Birthday",
    "dayIsYours": "This day is yours",
    "vacations": "Vacations",
    "allDay": "All day",
    "halfDay": "Half day",
    "months": {
      "1": "January",
      "2": "February",
      "3": "March",
      "4": "April",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "August",
      "9": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    }
  }
}
</i18n>
